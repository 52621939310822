import Field from '@blok/field'
import PageContext from '@context'
import { isUX1Country } from '@helpers'
import useStringTranslation from '@hooks/use-string-translation'
import { Box, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import React, { useContext } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles((theme) => ({
  interactiveFormGroupRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  formRow: {
    '& > div': {
      width: '100%',
    },
  },
  fieldLabel: {
    fontSize: '.75rem',
    color: theme.palette.common.black,
  },
}))

const safeCheckboxes = (field) => {
  const translatedText = useStringTranslation(field.text || '')
  return {
    ...field,
    text: translatedText,
  }
}

const InteractiveFormGroup = ({ blok, isNotMarketableForm }) => {
  const { locale } = useContext(PageContext)
  const classes = useStyles()
  const { fields } = blok

  const filteredFields =
    !isUX1Country(locale) && !isNotMarketableForm
      ? fields
      : fields.filter(
          (field) =>
            field.fieldId !== 'optinDataCollection' &&
            field.fieldId !== 'optinMarketingCommunications'
        )

  return (
    <SbEditable content={blok}>
      <div className={classes.interactiveFormGroupRoot}>
        {filteredFields?.length > 0 &&
          filteredFields.map((field) => {
            field.margin = 'dense'
            // Handle existing Marketo fields
            if (field.component === 'marketoFormField') {
              return (
                <Box className={classes.formRow} key={field._uid}>
                  <InputLabel
                    htmlFor={field?.fieldId?.trim()}
                    className={classes.fieldLabel}
                  >
                    {field?.fieldName}
                  </InputLabel>
                  <Field {...field} />
                </Box>
              )
            }
            // Handle checkboxes
            if (
              field.fieldId === 'optinDataCollection' ||
              field.fieldId === 'optinMarketingCommunications'
            ) {
              return (
                <div key={field._uid}>
                  {renderBlok(safeCheckboxes(field), {
                    fieldName: field.fieldName,
                  })}
                </div>
              )
            }
            // Handle interactive card select
            return (
              <div key={field._uid}>
                {renderBlok(field, {
                  fieldName: field.fieldName,
                })}
              </div>
            )
          })}
      </div>
    </SbEditable>
  )
}

export default InteractiveFormGroup
